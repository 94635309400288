<template>
  <component
    :is="blockComponent"
    :model="model"
    :placement="placement"
  />
</template>

<script setup lang="ts">
import type { IContent } from '~/content';
import { type ImagesSizes } from '~/composables/useImages';

interface Props {
  model: IContent,
  placement?: ImagesSizes
}
const props = withDefaults(defineProps<Props>(), {
  placement: 'bottom',
});

const blockComponent = computed(() => {
  // Pick the most specific component, i.e. first component that matches
  // the the content type name in the content type inheritance chain.
  for (let i = props.model.contentType.length - 1; i >= 0; i -= 1) {
    const resolved = resolveComponent(props.model.contentType[i]);
    if (typeof resolved === 'object') {
      return resolved;
    }
  }

  return 'GenericBlock';
});
</script>
